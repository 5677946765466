/**
 * @description: 日常接口
 */
import { http } from '@/plugins'
import { yf_manage } from '@/config'
import qs from 'qs';

const daily = {
    list(params) {
        const key = sessionStorage.getItem("yfkey") || "all"
        return http.get(`${yf_manage.url}/api0/daily?` + qs.stringify({ key, ...params }))
    },
    // 加载图片
    getImg(params) {
        const key = sessionStorage.getItem("yfkey") || "all"
        return http.get(`${yf_manage.url}/api0/getImg?` + qs.stringify({ key, ...params }))
    }

}
export { daily }