/*
 * @Descripttion: 
 * @Author: yunfei
 * @Date: 2022-12-29 16:06:15
 */
import { http } from '@/plugins'
import { juejin_proxy } from '@/config'

const juejin = {
    gold(params) {
        return http.post(`${juejin_proxy.path}/resources/gold`, params)
    }
}
export { juejin }