/*
 * @Descripttion: 
 * @Author: yunfei
 * @Date: 2022-12-29 16:06:16
 */
const juejin_proxy = { path: '/api2', url: "https://e.juejin.cn" }
const douban_proxy = { path: "/api3", url: "https://movie.douban.com" }
const guojiaqixiangju_proxy = { path: "/api4", url: "http://data.cma.cn" }
const yf_manage = { path: '/api5', url: "http://asset.yunfei.ltd" }
// const yf_manage = { path: '/api5', url: "http://192.168.31.232:8100" }
// const yf_manage = { path: '/api5', url: "http://172.18.9.116:8100" }
export { juejin_proxy, douban_proxy, guojiaqixiangju_proxy, yf_manage }