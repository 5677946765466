/*
 * @Descripttion: 
 * @Author: yunfei
 * @Date: 2022-12-29 16:06:16
 */
import axios from "axios";
// import { ElMessage } from 'element-plus'


const http = axios.create({
    baseURL: "",
    timeout: 30000
});

/**请求拦截器 */

http.interceptors.request.use((config) => {
    // config.headers.yunfei = localStorage.getItem('pw') || 'kankan'
    // config.headers['authority'] = 'api.juejin.cn'
    

    // 发送前 配置
    return config;
}, (err) => {
    // 请求错误
    return Promise.reject(err);
})

/**响应拦截器 */
http.interceptors.response.use((res) => {
    return res;
}, (err) => {
    console.log(`数据请求失败： ${JSON.stringify(err)}`)
    // ElMessage({
    //     message: `数据请求失败 \n 
    //         错误内容: \n
    //         ${JSON.stringify(err)}
    //     `,
    //     type:'error',
    //     duration:4000
    // })
    return Promise.reject(err);
})
export default http