/**
 * @description: 百度热点
 */
 import { http } from '@/plugins'
 import { yf_manage } from '@/config'
 
 const panel = {
    baiduHotspot() {
         return http.get(`${yf_manage.url}/api0/baiduHotspot`)
     },
 
 }
 export { panel }