import { http } from '@/plugins'
import { guojiaqixiangju_proxy } from '@/config'

const tianqi = {
    getStationID(params) {
        return http.post(`${guojiaqixiangju_proxy.path}/kbweb/home/getStationID`, params)
    },
    live(params) {
        return http.post(`${guojiaqixiangju_proxy.path}/kbweb/home/live`, params)
    },
    // // 地区
    // ipquery() {
    //     return http.get(`http://ip.ws.126.net/ipquery`)
    // },
}
export { tianqi }